import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { adminAction } from '../../../redux/common/action'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'

import ImageUploading from 'react-images-uploading'
import { toast } from 'react-toastify'
// @ts-ignore
import { Country, State, City } from 'country-state-city'

import Select from 'react-select'
import { URL } from '../../../redux/common/url'

function Profile() {
  const location = useLocation()
  const dispatch = useDispatch()

  const searchParams = new URLSearchParams(location.search)
  const idParam = searchParams.get('id')

  console.log(idParam)

  const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const [showOtpBoxsata, setShowOtpBoxdata] = useState<any>('')

  const [images, setImages] = React.useState<any>([])
  const maxNumber = 1

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList)
  }

  const [imagesss, setImagesss] = React.useState<any>([])
  const maxNumberss = 1

  const onChangess = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImagesss(imageList)
  }

  // getAdminBussProfile
  //   get_admin_profile

  const get_admin_profile: any = useSelector((state: any) =>
    state.admin.get_admin_profile ? state.admin.get_admin_profile : {}
  )

  var size = Object.keys(get_admin_profile).length

  console.log(get_admin_profile)

  useEffect(() => {
    dispatch(adminAction.getAdminBussProfile(idParam))

    return () => { }
  }, [idParam])

  //   createBussnissPro
  //   createBussnissPro

  const [show, setShow] = useState<any>(false)
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [userBuss, setuserBuss] = useState<any>({
    businessName: '',
    address: '',
    city: '',
    country: '',
    whatsappNumber: '',
    countryCode: '',
  })

  const hendleChangerole = (e: any) => {
    const { name, value } = e.target
    setuserBuss({ ...userBuss, [name]: value })
  }

  const countryList = Country.getAllCountries()
  const cityListList = City.getAllCities()

  const [subList, setsubList] = useState<any>([])
  const [subListCity, setsubListCity] = useState<any>([])

  useEffect(() => {
    const dropList: any = countryList?.map((data: any, i: any) => {
      data.value = data?.isoCode
      data.label = data?.name

      return data
    })

    setsubList(dropList)
  }, [countryList])
  useEffect(() => {
    const dropList: any = cityListList?.map((data: any, i: any) => {
      data.value = data?.stateCode
      data.label = data?.name

      return data
    })

    setsubListCity(dropList)
  }, [cityListList])

  const [subListCityfil, setsubListCityfil] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedOptionCity, setSelectedOptionCity] = useState<any>(null)

  useEffect(() => {
    // const found = subListCity.find(
    //   (element) => element?.countryCode == selectedOption?.isoCode
    // );

    const listFilCity: any =
      subListCity &&
      subListCity?.filter((data: any) => {
        return data?.countryCode == selectedOption?.isoCode
      })
    // console.log(listFilCity);
    setsubListCityfil(listFilCity)
    setSelectedOptionCity(null)
  }, [selectedOption])

  const submitProduct = () => {
    try {
      const data = new FormData()

      if (!userBuss?.businessName) {
        toast.error('please enter buseniss name')
      } else if (!userBuss?.address) {
        toast.error('please enter address')
      } else if (selectedOptionCity == null) {
        toast.error('please enter city')
      } else if (selectedOption == null) {
        toast.error('please enter country')
      } else if (!userBuss?.countryCode) {
        toast.error('please enter country code')
      } else if (!userBuss?.whatsappNumber) {
        toast.error('please enter whatsapp number')
      } else if (images?.length == 0) {
        toast.error('please select logo Image')
      } else {
        // setLoading(true);

        data.append('businessName', userBuss?.businessName)
        data.append('address', userBuss?.address)
        data.append('city', selectedOptionCity?.name)
        data.append('country', selectedOption?.name)
        data.append('whatsappNumber', userBuss?.whatsappNumber)
        data.append('countryCode', userBuss?.countryCode)

        data.append('logo', images[0]?.file)

        dispatch(adminAction.createBussnissPro(data))
        // setShow(false);
        setShowOtpBox(true)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const submitChangeVerify2 = () => {
    if (!showOtpBoxsata) {
      toast.error('please enter otp')
    } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
      toast.error('otp invalid')
    } else {
      dispatch(
        adminAction.verifyWhatsapp({
          whatsappNumber: userBuss?.whatsappNumber,
          otp: showOtpBoxsata,
        })
      )
      setShowOtpBox(false)
      setShow(false)
    }
  }

  const [showwhat, setShowwhat] = useState<any>(false)

  const handleClosewhat = () => setShowwhat(false)
  const handleShowwhat = () => setShowwhat(true)

  const [showLogo, setshowLogo] = useState<any>(false)
  const [imageview, setimageview] = useState<any>("")

  const handleCloseshowLogo = () => setshowLogo(false)
  const handleShowshowLogo = (e: any) => {
    setimageview(e)
    setshowLogo(true)
  }

  const [showeditBox, setshoweditBox] = useState<any>(false)

  const handleCloseeditBox = () => setshoweditBox(false)
  const handleShoweditBox = () => setshoweditBox(true)

  const [showOtp, setshowOtp] = useState<any>(false)
  const [whatsappNum, setwhatsappNum] = useState<any>('')
  const [countryCode, setcountryCode] = useState<any>('')
  const [whatsappOtp, setwhatsappOtp] = useState<any>('')
  const [bussEdit, setbussEdit] = useState<any>({
    businessName: '',
    address: '',
    city: '',
    country: '',
  })

  const [subListedit, setsubListedit] = useState<any>([])
  const [subListCityedit, setsubListCityedit] = useState<any>([])

  const [selectedOptionEdit, setSelectedOptionEdit] = useState<any>(null)
  const [selectedOptionCityEdit, setSelectedOptionCityEdit] = useState<any>(null)

  useEffect(() => {
    const listFilCity =
      subListCity &&
      subListCity?.filter((data: any) => {
        return data?.countryCode == selectedOptionEdit?.isoCode
      })
    // console.log(listFilCity);
    setsubListCityedit(listFilCity)
    setSelectedOptionCityEdit(null)
  }, [selectedOptionEdit])

  useEffect(() => {
    const dropList = countryList?.map((data: any, i: any) => {
      data.value = data?.isoCode
      data.label = data?.name

      return data
    })

    setsubListedit(dropList)
    let obj = dropList.find((o: any) => o.name === get_admin_profile?.country)

    setSelectedOptionEdit(obj)

    const listFilCity =
      subListCity &&
      subListCity?.filter((data: any) => {
        return data?.countryCode == obj?.isoCode
      })
    console.log(listFilCity)
    console.log(get_admin_profile?.city)
    setsubListCityedit(listFilCity)
    let obj2 = listFilCity.find((o: any) => o.name === get_admin_profile?.city)

    setSelectedOptionCityEdit(obj2)
  }, [get_admin_profile])
  console.log(get_admin_profile)

  useEffect(() => {
    setbussEdit({
      businessName: get_admin_profile?.businessName,
      address: get_admin_profile?.address,
      city: get_admin_profile?.city,
      country: get_admin_profile?.country,
    })

    setwhatsappNum(get_admin_profile?.user_id?.whatsappNumber)
    setcountryCode(get_admin_profile?.user_id?.countryCode)
  }, [get_admin_profile])

  const hendleChangeupdate = (e: any) => {
    const { name, value } = e.target
    setbussEdit({ ...bussEdit, [name]: value })
  }

  const submitupdateBussProfile = () => {
    try {
      const data = new FormData()

      if (!bussEdit?.businessName) {
        toast.error('please enter buseniss name')
      } else if (!bussEdit?.address) {
        toast.error('please enter address')
      } else if (selectedOptionCityEdit == null) {
        toast.error('please enter city')
      } else if (selectedOptionEdit == null) {
        toast.error('please enter country')
      } else {
        // setLoading(true);

        // data.append("businessName", bussEdit?.businessName);
        // data.append("address", bussEdit?.address);
        // data.append("city", bussEdit?.city);
        // data.append("country", bussEdit?.country);
        // data.append("whatsappNumber", bussEdit?.whatsappNumber);

        // data.append("logo", images[0]?.file);

        dispatch(
          adminAction.updateBussProfile({
            businessName: bussEdit?.businessName,
            address: bussEdit?.address,
            country: selectedOptionEdit?.name,
            city: selectedOptionCityEdit?.name,
          })
        )
        setshoweditBox(false)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const submitChangeW = () => {
    if (!whatsappNum) {
      toast.error('please enter whatsapp number')
    } else if (whatsappNum?.length < 10 || whatsappNum?.length > 10) {
      toast.error('number invalid')
    } else {
      dispatch(
        adminAction.updateWhatsaapNumber({
          whatsappNumber: whatsappNum,
          countryCode: countryCode,
        })
      )
      setshowOtp(true)
    }
  }

  const submitChangeVerify = () => {
    if (!whatsappOtp) {
      toast.error('please enter otp')
    } else if (whatsappOtp?.length < 6 || whatsappOtp?.length > 6) {
      toast.error('otp invalid')
    } else {
      dispatch(
        adminAction.verifyWhatsapp({
          whatsappNumber: whatsappNum,
          otp: whatsappOtp,
        })
      )
      setShowwhat(false)
      setshowOtp(false)
    }
  }

  const hendleLogoUpdate = () => {
    try {
      const data = new FormData()

      if (imagesss?.length == 0) {
        toast.error('please select logo Image')
      } else {
        // setLoading(true);

        data.append('logo', imagesss[0]?.file)

        dispatch(adminAction.updateBussLogo(data))
        setshowLogo(false)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            {!showOtpBox ? (
              <div className='card-body'>
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>Select Country</label>
                  <Select
                    className='coustomSelect'
                    classNamePrefix='coustomSelect'
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>Select City</label>
                  <Select
                    className='coustomSelect'
                    classNamePrefix='coustomSelect'
                    defaultValue={selectedOptionCity}
                    onChange={setSelectedOptionCity}
                    options={subListCityfil}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Address</label>
                  <input
                    name='address'
                    className='form-control'
                    placeholder='Enter address'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                {/* <div className="mb-3">
                    <label for="teg" className="form-label">
                      City
                    </label>
                    <input
                      name="city"
                      className="form-control"
                      placeholder="Enter city"
                      type="text"
                      onChange={(e) => {
                        hendleChangerole(e);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="teg" className="form-label">
                      Country
                    </label>
                    <input
                      name="country"
                      className="form-control"
                      placeholder="Enter country"
                      type="text"
                      onChange={(e) => {
                        hendleChangerole(e);
                      }}
                    />
                  </div> */}
                <div className='mb-3'>
                  <label className='form-label'>Country Code</label>
                  <input
                    name='countryCode'
                    className='form-control'
                    placeholder='Enter Enter Code'
                    type='Number'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Whatsapp Number</label>
                  <input
                    name='whatsappNumber'
                    className='form-control'
                    placeholder='Enter Whatsapp Number'
                    type='Number'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>Business Logo</label>
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey='data_url'
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className='upload__image-wrapper'>
                        <button
                          className='btn btn-primary btn-sm rounded'
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        <button
                          className='btn btn-primary btn-sm rounded'
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item'>
                            <img src={image['data_url']} alt='' width='100' />
                            <div className='image-item__btn-wrapper'>
                              <button
                                className='btn btn-primary btn-sm rounded'
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className='btn btn-primary btn-sm rounded'
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>

                {/* <div className="mb-3">
                  <label className="form-label">Select Category</label>
                  <Select
                    className="coustomSelect"
                    classNamePrefix="coustomSelect"
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                  
                </div> */}
              </div>
            ) : (
              <div className='card-body'>
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    value={showOtpBoxsata}
                    onChange={(e) => {
                      setShowOtpBoxdata(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>

        {!showOtpBox ? (
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitProduct()
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => {
                submitChangeVerify2()
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showeditBox} onHide={handleCloseeditBox}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            <div className='card-body'>
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className='mb-3'>
                <label className='form-label'>Business Name</label>
                <input
                  name='businessName'
                  className='form-control'
                  placeholder='Enter Business name'
                  type='text'
                  value={bussEdit?.businessName}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Select Country</label>
                <Select
                  className='coustomSelect'
                  classNamePrefix='coustomSelect'
                  defaultValue={selectedOptionEdit}
                  onChange={setSelectedOptionEdit}
                  options={subListedit}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Select City</label>
                <Select
                  className='coustomSelect'
                  classNamePrefix='coustomSelect'
                  defaultValue={selectedOptionCityEdit}
                  onChange={setSelectedOptionCityEdit}
                  options={subListCityedit}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Address</label>
                <input
                  name='address'
                  className='form-control'
                  placeholder='Enter address'
                  type='text'
                  value={bussEdit?.address}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              submitupdateBussProfile()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  whatsapp */}

      <Modal show={showwhat} onHide={handleClosewhat}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            {/* <h4 className="card-title mb-4">Feature Image</h4> */}
            {!showOtp ? (
              <div className='card-body'>
                <div className='mb-3'>
                  <label className='form-label'>Whatsapp Number</label>
                  <input
                    name='countryCode'
                    className='form-control'
                    placeholder='Enter whatsapp number'
                    type='text'
                    value={countryCode}
                    onChange={(e) => {
                      setcountryCode(e.target.value)
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Whatsapp Number</label>
                  <input
                    name='whatsappNumber'
                    className='form-control'
                    placeholder='Enter whatsapp number'
                    type='text'
                    value={whatsappNum}
                    onChange={(e) => {
                      setwhatsappNum(e.target.value)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='card-body'>
                <div className='mb-3'>
                  <label className='form-label'>Otp</label>
                  <input
                    name='otp'
                    className='form-control'
                    placeholder='Enter whatsapp otp'
                    type='text'
                    value={whatsappOtp}
                    onChange={(e) => {
                      setwhatsappOtp(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {!showOtp ? (
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClosewhat}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitChangeW()
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setshowOtp(false)
              }}
            >
              Change number
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitChangeVerify()
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showLogo} onHide={handleCloseshowLogo}>

        <Modal.Body>
          {' '}
          <div  >
            <div  >
              <img src={imageview} alt='' style={{ width: "100%" }} />
            </div>
          </div>
        </Modal.Body>


      </Modal>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    get_admin_profile?.profile_image?.startsWith('http')
                      ? get_admin_profile?.profile_image
                      : URL.API_BASE_URL + get_admin_profile?.profile_image
                  }
                  alt=''
                />
                {/* <img
                  src={
                    'https://lh3.googleusercontent.com/a/AGNmyxaJot2asK_T0ILlfcdN8Iwb1_5itmzaoaNvpGAOYA=s96-c'
                  }
                  alt=''
                /> */}
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {get_admin_profile?.name}
                    </a>
                    {/* <a href='#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a> */}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      Role :-
                      {' ' + get_admin_profile?.role}
                    </a>
                    {get_admin_profile?.mobile && (
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        {/* <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                        Mobile No :-
                        {' ' + get_admin_profile?.country_code + ' '}
                        {get_admin_profile?.mobile}
                      </a>
                    )}
                    {get_admin_profile?.social_type && (
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        {/* <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      /> */}{' '}
                        Social type :-
                        {' ' + get_admin_profile?.social_type + ' '},
                      </a>
                    )}{' '}
                    {get_admin_profile?.social_id && (
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        {/* <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      /> */}{' '}
                        Social_id :-
                        {' ' + get_admin_profile?.social_id + ' '},
                      </a>
                    )}{' '}
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      Rating :-
                      {' ' + get_admin_profile?.rating}
                    </a>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>City</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.city}</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr065.svg'
                          className='svg-icon-3 svg-icon-danger me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>State</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.state}</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>Address</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.address}</div>
                    </div>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                      
                        <div className='fs-2 fw-bolder'>Address</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.address}</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {get_admin_profile?.labour_detail && <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    get_admin_profile?.labour_detail?.aadhar_front
                      ? URL.API_BASE_URL + get_admin_profile?.labour_detail?.aadhar_front
                      :"/No_Image_Available.jpg"
                  }
                  alt=''
                />

                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
              <h6 className='mt-2'>Aadhar Card </h6>
            </div>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    get_admin_profile?.labour_detail?.aadhar_back
                      ? URL.API_BASE_URL + get_admin_profile?.labour_detail?.aadhar_back
                      : "/No_Image_Available.jpg"
                  }
                  alt=''
                />

                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
              <h6 className='mt-2'>Other </h6>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {get_admin_profile?.name}
                    </a>
                    {/* <a href='#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a> */}
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      Category
                      :-
                      {' ' + get_admin_profile?.labour_detail?.labour_category}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      Other Experience
                      :-
                      {' ' + get_admin_profile?.labour_detail?.other_experience}
                    </a>
                    {/* <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      
                      Rate Card
                      :-
                      {' ' + get_admin_profile?.labour_detail?.rate_card}
                    </a> */}
                    {/* {get_admin_profile?.mobile && (
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        
                        Mobile No :-
                        {' ' + get_admin_profile?.country_code + ' '}
                        {get_admin_profile?.mobile}
                      </a>
                    )} */}

                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>Experience</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.labour_detail?.experience}</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr065.svg'
                          className='svg-icon-3 svg-icon-danger me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>Rate Card</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.labour_detail?.rate_card}</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>Over Time Rate</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.labour_detail?.over_time_rate}</div>
                    </div>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                      
                        <div className='fs-2 fw-bolder'>Address</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.address}</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>}

          <div className='d-flex overflow-auto h-55px'>
            {/* <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/overview' && 'active')
                  }
                  to='/crafted/pages/profile/overview'
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/projects' && 'active')
                  }
                  to='/crafted/pages/profile/projects'
                >
                  Projects
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                  }
                  to='/crafted/pages/profile/campaigns'
                >
                  Campaigns
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/documents' && 'active')
                  }
                  to='/crafted/pages/profile/documents'
                >
                  Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/connections' && 'active')
                  }
                  to='/crafted/pages/profile/connections'
                >
                  Connections
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
     
          {get_admin_profile?.labour_work?.length > 0 && (
            <div className='card-body pt-9 pb-0'>
              <hr />
              <h2>Labour work</h2>
              <div className='row p-3'>
              {get_admin_profile?.labour_work?.map((data: any, i: any) => {
                return (




                  <div className='col-3 '>
                    <div className='labCont'>
                    <div className='labimg' style={{ width: '200px', height: "200px", overflow: "hidden",margin:"auto" }} onClick={() => { handleShowshowLogo(data?.image?URL.API_BASE_URL + data?.image :"/No_Image_Available.jpg") }}>
                      <img style={{ width: '100%', height: "auto" }} src={data?.image?URL.API_BASE_URL + data?.image :"/No_Image_Available.jpg"} alt='' />
                    </div>
                    <h5>{data?.title}</h5>
                    </div>

              
                  </div>



                )
              })}
                </div>
              <hr />
            </div>
          )}

      
        {/* {get_admin_profile?.labour_work?.length > 0 && (
          <div className='card-body pt-9 pb-0'>
            <hr />
            <h2>Labour work</h2>
            {get_admin_profile?.labour_work?.map((data: any, i: any) => {
              return (
                <div className={i % 2 ? 'bg-light' : ""} key={i}>
                  <div className='row p-3'>
                    <div className='col-6'>
                      <h5>{data?.title}</h5>
                    </div>

                    <div className='col-6'>
                      <div className='labimg' style={{ width: '200px', height: "200px", overflow: "hidden" }} onClick={() => { handleShowshowLogo(URL.API_BASE_URL + data?.image) }}>
                        <img style={{ width: '100%', height: "auto" }} src={URL.API_BASE_URL + data?.image} alt='' />
                      </div>
                    </div>
                  </div>
                </div>

              )
            })}
            <hr />
          </div>
        )} */}



{get_admin_profile?.posts?.length > 0 && (
            <div className='card-body pt-9 pb-0'>
            <h2>Posts</h2>
              <div className='row p-3'>
              {get_admin_profile?.posts.map((data: any, i: any) => {
                return (




                  <div className='col-3 '>
                    <div className='labCont'>
                    <div className='labimg' style={{ width: '200px', height: "200px", overflow: "hidden",margin:"auto" }} onClick={() => { handleShowshowLogo(data?.image?URL.API_BASE_URL + data?.image :"/No_Image_Available.jpg") }}>
                      <img style={{ width: '100%', height: "auto" }} src={data?.image?URL.API_BASE_URL + data?.image :"/No_Image_Available.jpg" } alt='' />
                    </div>
                    {/* <h5>{data?.title}</h5> */}
                    <h5>{data?.title}</h5>
                      <h5>Link : - {' ' + data?.likes_count}</h5>
                      <h5>Comments count :- {' ' + data?.comments_count}</h5>
                    </div>

              
                  </div>
                )
              })}
                </div>
              <hr />
            </div>
          )}
        {/* {get_admin_profile?.posts?.length > 0 && (
          <div className='card-body pt-9 pb-0'>
            <h2>Posts</h2>
            {get_admin_profile?.posts?.map((data: any, i: any) => {
              return (
                <div className={i % 2 ? 'bg-light' : ""} key={i}>
                  <div className='row p-3'>
                    <div className='col-6'>
                      <h5>{data?.title}</h5>
                      <h5>Link : - {' ' + data?.likes_count}</h5>
                      <h5>Comments count :- {' ' + data?.comments_count}</h5>
                    </div>

                    <div className='col-6'>
                      <div className='labimg' style={{ width: '200px', height: "200px", overflow: "hidden" }}>
                        <img style={{ width: '100%', height: "auto" }} src={URL.API_BASE_URL + data?.image} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )} */}
      </div>
    </div>
  )
}

export default Profile
