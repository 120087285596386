import axios from 'axios'
import {Suspense, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

// multivendor

// QS2bl10japblBUIv

const App = () => {
  const [showView, setshowView] = useState<any>(true)
  const [message, setmessage] = useState<any>('')

  useEffect(() => {
    // var axios = require('axios');
    // var FormData = require('form-data');
    // var data = new FormData();

    var config: any = {
      method: 'get',
      url: 'https://frontapi.devhubtech.com/api/checkApp?platform=admin&packagename=mainadmin.dreamslly.com',
      // headers: {
      //   ...data.getHeaders()
      // },
      // data : data
    }

    axios(config)
      .then(function (response: any) {
        console.log(JSON.stringify(response.data))
        if (response.data?.status) {
          setshowView(response.data?.data?.paid)
          setmessage(response.data?.data?.deactivemessage)
        } else {
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Modal show={!showView}>
              {/* <Modal.Header >
          <Modal.Title> Details</Modal.Title>
        </Modal.Header> */}
              <Modal.Body>
                <h6>{message}</h6>
              </Modal.Body>
            </Modal>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
