export const LOADING= "LOADING";
//user constant
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";









//university Constant
// export const  GET_UNIVERSITIES_LIST = "GET_UNIVERSITIES_LIST"
// export const  GET_UNIVERSITIES_BY_ID = "GET_UNIVERSITIES_BY_ID"
// export const  GET_UNIVERSITIES = "GET_UNIVERSITIES"
// export const  GET_ALL_UNIVERSITIES_COMMENT = "GET_ALL_UNIVERSITIES_COMMENT"
// export const  ADD_UNIVERSITIES_COMMENT = "ADD_UNIVERSITIES_COMMENT"
// export const  GET_RANKING_UNIVERSITIES = "GET_RANKING_UNIVERSITIES"
// export const  GET_UNIVERSITIES_BY_SLUG = "GET_UNIVERSITIES_BY_SLUG"




// permission
// dashboard
export const  GET_CATE = "GET_CATE"
export const  GET_DASHBOARD = "GET_DASHBOARD"
export const  GET_PERMISSION_LISTS = "GET_PERMISSION_LISTS"
export const  GET_ROLES_LISTS = "GET_ROLES_LISTS"
export const  GET_PRODUCT_LISTS = "GET_PRODUCT_LISTS"
export const  GET_PACKAGE_LISTS = "GET_PACKAGE_LISTS"
export const  GET_PACKAGE_LISTS_BUY = "GET_PACKAGE_LISTS_BUY"
export const  CREATE_PRODUCT = "CREATE_PRODUCT"
export const  GET_CATEGORY_LISTS = "GET_CATEGORY_LISTS"
export const  GET_TRANSACTION_LISTS = "GET_TRANSACTION_LISTS"
export const  GET_CATEGORY_ALL_LISTS = "GET_CATEGORY_ALL_LISTS"
export const  GET_SUB_CATEGORY_LISTS = "GET_SUB_CATEGORY_LISTS"
export const  GET_SUB_CATEGORY_ALL_LISTS = "GET_SUB_CATEGORY_ALL_LISTS"
export const  GET_SUB_SUB_CATE_LISTS = "GET_SUB_SUB_CATE_LISTS"
export const  GET_SUB_SUB_CATEGORY_LISTS = "GET_SUB_SUB_CATEGORY_LISTS"
export const  GET_SELLER_LISTS = "GET_SELLER_LISTS"
export const  GET_SELLER_LISTS_PRO = "GET_SELLER_LISTS_PRO"
export const  GET_TICKET_LISTS = "GET_TICKET_LISTS"
export const  GET_MESSAGE_LISTS = "GET_MESSAGE_LISTS"
export const  GET_INVOICE = "GET_INVOICE"
export const  GET_SELLER_PROFILE = "GET_SELLER_PROFILE"
export const  GET_PAGES = "GET_PAGES"
export const  GET_WHATSAPP_QU = "GET_WHATSAPP_QU"
export const  GET_EMAIL_QU = "GET_EMAIL_QU"
export const  GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const  GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE"
export const  GET_BUSS_PROFILE = "GET_BUSS_PROFILE"
export const  GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS"
export const  CREATE_TAGS = "CREATE_TAGS"
export const  SEARCH_TAGS = "SEARCH_TAGS"
export const  FOOTER_PAGE_TYPE = "FOOTER_PAGE_TYPE"
export const  FOOTER_PAGE_LIST = "FOOTER_PAGE_LIST"
export const  FOOTER_PAGE_LIST_SELECT = "FOOTER_PAGE_LIST_SELECT"
export const  ANALYTICS_PRO_DATE = "ANALYTICS_PRO_DATE"
export const  TOP_VIEW_PRO = "TOP_VIEW_PRO"
export const  TOP_VIEW_PRO_BY_CATE = "TOP_VIEW_PRO_BY_CATE"
export const  TOP_VIEW_PRO_BY_SELLER = "TOP_VIEW_PRO_BY_SELLER"
export const  TOP_VIEW_ALL_TYPE = "TOP_VIEW_ALL_TYPE"
export const  CREATE_SLIDER = "CREATE_SLIDER"
export const  CREATE_BANNERS = "CREATE_BANNERS"
export const  BLOG = "BLOG"
export const  HOME_POP = "HOME_POP"
export const  FAQS_LIST = "FAQS_LIST"
export const  LOGO_AND_OTHER = "LOGO_AND_OTHER"
export const  GET_TEAMS_LIST_FOR_WINNER_SELECT = "GET_TEAMS_LIST_FOR_WINNER_SELECT"















