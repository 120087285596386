/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../redux/common/action'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget3,
  ChartsWidget4,
} from '../../../_metronic/partials/widgets'
import {ChartsWidget9} from '../../../_metronic/partials/widgets/charts/ChartsWidget9'

const DashboardPage: FC = () => {
  const analitics_prodact_by_date: any[] = useSelector((state: any) =>
    state.admin.analitics_prodact_by_date ? state.admin.analitics_prodact_by_date : []
  )
  const top_view_pro: any[] = useSelector((state: any) =>
    state.admin.top_view_pro ? state.admin.top_view_pro : []
  )
  const top_view_pro_by_cate: any[] = useSelector((state: any) =>
    state.admin.top_view_pro_by_cate ? state.admin.top_view_pro_by_cate : []
  )
  const top_view_pro_by_seller: any[] = useSelector((state: any) =>
    state.admin.top_view_pro_by_seller ? state.admin.top_view_pro_by_seller : []
  )
  const get_dashborad: any = useSelector((state: any) =>
    state.admin.get_dashborad ? state.admin.get_dashborad : {}
  )

  // console.log(analitics_prodact_by_date)
  console.log(get_dashborad)

  var countDataProda: any = []
  var categoriesDataaProda: any = []

  var countViewTotal: any = []
  var proDuctName: any = []

  //  by cate
  var countViewTotalByCate: any = []
  var proDuctNameByCate: any = []
  // seller
  var countViewTotalBySeller: any = []
  var proDuctNameBySeller: any = []

  analitics_prodact_by_date.forEach((row: any) => {
    categoriesDataaProda.push(`${row?._id?.month}/${row?._id?.year}`)
    countDataProda.push(row.total_view)
  })

  top_view_pro_by_cate.forEach((row: any) => {
    countViewTotalByCate.push(row.totalViews)
    proDuctNameByCate.push(row._id)
  })

  top_view_pro.forEach((row: any) => {
    countViewTotal.push(row.totalViews)
    proDuctName.push(row._id)
  })
  top_view_pro_by_seller.forEach((row: any) => {
    countViewTotalBySeller.push(row.totalViews)
    proDuctNameBySeller.push(row._id)
  })

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-6'>
          <EngageWidget10 className='h-md-100' />
        </div>
        <div className='col-xxl-6'>
          <div className='row'>
            <div className='col-12 col-sm-6 mt-3'>
              <div className='bg-success text-white p-3 dashbox'>
                <h5>User</h5>
                <h4>{get_dashborad?.users}</h4>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-3'>
              <div className='bg-danger text-white p-3 dashbox'>
                <h5>Labours</h5>
                <h4>{get_dashborad?.labours}</h4>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-3'>
              <div className='bg-primary text-white p-3 dashbox'>
                <h5>Post</h5>
                <h4>{get_dashborad?.post}</h4>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-3'>
              <div className='bg-warning text-white p-3 dashbox'>
                <h5>Notifications</h5>
                <h4>{get_dashborad?.notifications}</h4>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countViewTotal}
              x={proDuctName}
              type='Top Product View'
            />
          </div>
          <div className='col-xl-6'>
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countViewTotalByCate}
              x={proDuctNameByCate}
              type='Top Category View'
            />
          </div>
          <div className='col-xl-6'>
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countViewTotalBySeller}
              x={proDuctNameBySeller}
              type='Top Seller View'
            />
          </div>
          <div className='col-xl-12'>
            <ChartsWidget9
              className='card-xl-stretch mb-xl-8'
              y={countViewTotalBySeller}
              x={proDuctNameBySeller}
              type='Top Seller View'
            />
          </div>
        </div> */}
      </div>

      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gx-5 gx-xl-10'>
     
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        
      </div>
           <div className='col-xxl-6 mb-5 mb-xl-10'>
       
      </div>
  
    </div> */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}

      {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
       
      </div>
    </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(adminAction.productdateWise({}))
    // dispatch(adminAction.topViewproduct({}))
    dispatch(adminAction.dashboard({}))
    // dispatch(adminAction.topViewproductBycate({}))
    // dispatch(adminAction.topViewproductBySeller({}))

    return () => {}
  }, [])

  // productdateWise
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
