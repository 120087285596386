import { 
  LOADING,

  GET_PERMISSION_LISTS,
  GET_ROLES_LISTS,
  GET_PRODUCT_LISTS,
  GET_CATEGORY_LISTS,
  GET_SELLER_LISTS,
  GET_SUB_SUB_CATEGORY_LISTS,
  GET_SELLER_LISTS_PRO,
  GET_CATEGORY_ALL_LISTS,
  GET_SUB_SUB_CATE_LISTS,
  GET_SUB_CATEGORY_LISTS,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_PACKAGE_LISTS,
  GET_TRANSACTION_LISTS,
  GET_TICKET_LISTS,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_SELLER_PROFILE,
  GET_PAGES,
  GET_WHATSAPP_QU,
  GET_SUBSCRIPTION,
  GET_ADMIN_PROFILE,
  GET_INVOICE_DETAILS,
  GET_PACKAGE_LISTS_BUY,
  GET_EMAIL_QU,
  CREATE_TAGS,
  SEARCH_TAGS,
  FOOTER_PAGE_TYPE,
  FOOTER_PAGE_LIST,
  FOOTER_PAGE_LIST_SELECT,
  ANALYTICS_PRO_DATE,
  TOP_VIEW_PRO,
  TOP_VIEW_PRO_BY_CATE,
  TOP_VIEW_PRO_BY_SELLER,
  TOP_VIEW_ALL_TYPE,
  CREATE_SLIDER,
  CREATE_BANNERS,
  BLOG,
  HOME_POP,
  FAQS_LIST,
  LOGO_AND_OTHER,
  GET_TEAMS_LIST_FOR_WINNER_SELECT,
  GET_DASHBOARD,
  GET_CATE,
   

} from "../common/constant";
 type stateType = {
  loading:boolean;
  get_per_list: any;
  get_role_list: any;
  get_product_list: any;
  get_package_list: any;
  get_category_list: any;
  get_transaction_list: any;
  get_ticket_list: any;
  get_sub_sub_category_list: any;
  get_sub_category_list: any;
  get_sub_sub_cate_list: any;
  get_category_all_list: any;
  get_sub_category_all_list: any;
  get_seller_list: any;
  get_seller_list_pro: any;
  get_invoice: any;
  get_invoice_details: any;
  get_package_list_buy: any;
  get_pages: any;
  get_tags_list: any;
  get_search_tags_list: any;
  get_admin_profile: any;
  get_subscription: any;
  get_seller_profile: any;
  get_message_list: any;
  get_whatsapp_qui: any;
  get_email_qui: any;
  counsellordetail: any;
  coachAndCounsellor: any;
  counsellorinfo: any;
  counsellordetailBySlug: any;
  footer_page_type: any;
  footer_page_list: any;
  footer_page_list_select: any;
  analitics_prodact_by_date: any;
  top_view_pro: any;
  top_view_pro_by_cate: any;
  create_Slider: any;
  create_banners: any;
  blog: any;
  home_pop: any;
  faqs_list: any;
  logo_other: any;
  get_dashborad: any;
  get_categggg: any;
  get_teams_list_for_winner_select: any;
}
const initialState : stateType = {
    loading:false,
    get_per_list: [],
    get_role_list: [],
    get_search_tags_list: [],
    get_message_list: [],
    get_product_list: {},
    get_package_list: {},
    get_package_list_buy: [],
    footer_page_type: [],
    footer_page_list: [],
    footer_page_list_select: [],
    get_category_list: {},
    get_sub_category_list: [],
    get_sub_sub_cate_list: {},
    get_transaction_list: {},
    get_ticket_list: {},
    get_invoice: {},
    get_pages: {},
    get_tags_list: {},
    get_subscription: {},
    get_seller_profile: {},
    get_whatsapp_qui: {},
    get_admin_profile: {},
    get_invoice_details: {},
    get_email_qui: {},
    get_sub_sub_category_list: [],
    get_category_all_list: [],
    get_sub_category_all_list: [],
    get_seller_list: {},
    get_categggg: {},
    blog: {},
    home_pop: {},
    faqs_list: {},
    logo_other: {},
    get_dashborad: {},
    get_seller_list_pro: [],
    counsellordetail: [],
    coachAndCounsellor: [],
    counsellorinfo: [],
    counsellordetailBySlug: [],
    analitics_prodact_by_date: [],
    top_view_pro: [],
    top_view_pro_by_cate: [],
    create_Slider: [],
    create_banners: [],
    get_teams_list_for_winner_select: [],
};

export default (state = initialState, action:any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case GET_PERMISSION_LISTS:
      return { ...state, get_per_list: action.payload};
    case GET_ROLES_LISTS:
      return { ...state, get_role_list: action.payload};
    case GET_PRODUCT_LISTS:
      return { ...state, get_product_list: action.payload};
    case GET_CATEGORY_LISTS:
      return { ...state, get_category_list: action.payload};
    case GET_SELLER_LISTS:
      return { ...state, get_seller_list: action.payload};
    case GET_SUB_SUB_CATEGORY_LISTS:
      return { ...state, get_sub_sub_category_list: action.payload};
    case GET_SELLER_LISTS_PRO:
      return { ...state, get_seller_list_pro: action.payload};
    case GET_CATEGORY_ALL_LISTS:
      return { ...state, get_category_all_list: action.payload};
    case GET_SUB_CATEGORY_LISTS :
      return { ...state, get_sub_category_list: action.payload};
    case GET_SUB_SUB_CATE_LISTS:
      return { ...state, get_sub_sub_cate_list: action.payload};
    case GET_SUB_CATEGORY_ALL_LISTS:
      return { ...state, get_sub_category_all_list: action.payload};
    case GET_PACKAGE_LISTS:
      return { ...state, get_package_list: action.payload};
      
    case GET_TRANSACTION_LISTS:
      return { ...state, get_transaction_list: action.payload};
    case GET_TICKET_LISTS:
      return { ...state, get_ticket_list: action.payload};
    case GET_MESSAGE_LISTS:
      return { ...state, get_message_list: action.payload};
    case GET_INVOICE:
      return { ...state, get_invoice: action.payload};
    case GET_SELLER_PROFILE:
      return { ...state, get_seller_profile: action.payload};
    case GET_PAGES:
      return { ...state, get_pages: action.payload};
    case GET_WHATSAPP_QU:
      return { ...state, get_whatsapp_qui: action.payload};
    case GET_SUBSCRIPTION:
      return { ...state, get_subscription: action.payload};
    case GET_ADMIN_PROFILE:
      return { ...state, get_admin_profile: action.payload};
    case GET_INVOICE_DETAILS:
      return { ...state, get_invoice_details: action.payload};
    case GET_PACKAGE_LISTS_BUY:
      return { ...state, get_package_list_buy: action.payload};
    case GET_EMAIL_QU:
      return { ...state, get_email_qui: action.payload};
    case CREATE_TAGS:
      return { ...state, get_tags_list: action.payload};
    case SEARCH_TAGS:
      return { ...state, get_search_tags_list: action.payload};
    case FOOTER_PAGE_TYPE:
      return { ...state, footer_page_type: action.payload};
    case FOOTER_PAGE_LIST:
      return { ...state, footer_page_list: action.payload};
    case FOOTER_PAGE_LIST_SELECT:
      return { ...state, footer_page_list_select: action.payload};
    case ANALYTICS_PRO_DATE:
      return { ...state, analitics_prodact_by_date: action.payload};
    case TOP_VIEW_PRO:
      return { ...state, top_view_pro: action.payload};
    case TOP_VIEW_PRO_BY_CATE:
      return { ...state, top_view_pro_by_cate: action.payload};
    case TOP_VIEW_PRO_BY_SELLER:
      return { ...state, top_view_pro_by_seller: action.payload};
    case TOP_VIEW_ALL_TYPE:
      return { ...state, top_view_all_typer: action.payload};
    case CREATE_SLIDER:
      return { ...state, create_Slider: action.payload};
    case CREATE_BANNERS:
      return { ...state, create_banners: action.payload};
    case BLOG:
      return { ...state, blog: action.payload};
    case HOME_POP:
      return { ...state, home_pop: action.payload};
    case FAQS_LIST:
      return { ...state, faqs_list: action.payload};
    case LOGO_AND_OTHER:
      return { ...state, logo_other: action.payload};
      case GET_TEAMS_LIST_FOR_WINNER_SELECT:
        return { ...state, get_teams_list_for_winner_select: action.payload };
      case GET_DASHBOARD:
        return { ...state, get_dashborad: action.payload };
      case GET_CATE:
        return { ...state, get_categggg: action.payload };

     
       
    default:
      return state;
  }
};