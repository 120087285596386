/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {adminAction} from '../../../../../redux/common/action'
import {useDispatch, useSelector} from 'react-redux'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const logo_other: any = useSelector((state: any) =>
    state.admin.logo_other ? state.admin.logo_other : {}
  )

  console.log(logo_other)

  // logoandOtherDetais

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getPackageList(1))
    // dispatch(adminAction.logoandOtherDetais({}))
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }, [])

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItemWithSub
        to='/categorys'
        title='Cateory'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/category' title='Category' hasBullet={true} />
        <SidebarMenuItem to='/subcategory' title='Sub Category' hasBullet={true} />
        <SidebarMenuItem to='/subsubcategory' title='Sub Sub Category' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/inquiry'
        title='Inbox'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/whatsapp-inquiry' title='Whatsapp Inquiry' hasBullet={true} />
        <SidebarMenuItem to='/email-inquiry' title='Email Inquiry' hasBullet={true} />
      
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItem
        to='/contacts'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Contacts'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/blogs'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Blogs'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/product'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Product'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/faqs'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Faqs'
        fontIcon='bi-layers'
      /> */}

      <SidebarMenuItem
        to='/category'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Category'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Users'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/labour'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Labour'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/post'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Post'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/notification'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Notification'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/slider'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Slider'
        fontIcon='bi-layers'
      />

      {/* <SidebarMenuItem
        to='/player'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Player'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/teams'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Teams'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/umpires'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Umpires'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/referee'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Referee'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/venue'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Venue'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/series-info'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Series Info'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/series-teams'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Series teams'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/match-squad'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Match Squad'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/match-info'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Match Info'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem
        to='/package'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Package'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/transaction'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Transaction'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/ticket'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Ticket'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/pages'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Pages'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/Tags'
        icon='/media/icons/duotune/general/gen051.svg'
        title='tags'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
