import React, { useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { adminAction } from '../../../redux/common/action'
import { Pagination, TableHeader } from '../Table'
import PaginationComponent from '../Table/Pagination/Pagination'
import { useFormik } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import Dropdown from 'react-bootstrap/Dropdown'

import { Country, State, City } from 'country-state-city'

// modal
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Link, useNavigate } from 'react-router-dom'
import { URL } from '../../../redux/common/url'
import { KTSVG } from '../../../_metronic/helpers'

// image uploader
import ImageUploading from 'react-images-uploading'
import { toast } from 'react-toastify'
// import { Toast } from 'react-bootstrap'
import Select from 'react-select'

const loginSchema = Yup.object().shape({
  role_name: Yup.string()
    // .role_name('Wrong role_name format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('product is required'),
  role_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('tegs is required'),
})

const initialValues = {
  role_name: '',
  role_description: '',
}

function Seller() {

  const navigate = useNavigate()




  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')
  const [pageNo, setpageNo] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(1)

  const [itemsPerPage, setitemsPerPage] = useState(10)

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({ field: '', order: '' })

  // deta table  state end

  // modal

  const [show, setShow] = useState(false)
  const [addpermission, setaddpermission] = useState(false)
  const [showDelelt, setShowDelete] = useState(false)

  const handleClose = () => setShow(false)
  const handleCloseaddpermission = () => setaddpermission(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShow = () => setShow(true)

  // modal end

  // api call

  const dispatch = useDispatch()

  // const [roledata, setroledata] = useState({})
  const [roledata, setroledata] = useState<any>({
    role_name: '',
    role_description: '',
    password: '',
    confrimPassword: '',
  })
  const [loading, setLoading] = useState(false)
  const [roleID, setroleID] = useState<any>('')
  const [productID, setproductID] = useState<any>('')

  // get api data

  // image uploder

  const [images, setImages] = React.useState<any>([])
  const maxNumber = 1

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImages(imageList)
  }
  const [imagesss, setImagesss] = React.useState([])
  const maxNumberss = 8

  const onChangess = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImagesss(imageList)
  }

  const counsellor: any[] = useSelector((state: any) =>
    state.admin.get_per_list ? state.admin.get_per_list : []
  )
  const get_role_list: any[] = useSelector((state: any) =>
    state.admin.get_role_list ? state.admin.get_role_list : []
  )
  const get_package_list_buy: any[] = useSelector((state: any) =>
    state.admin.get_package_list_buy ? state.admin.get_package_list_buy : []
  )
  const get_product_list: any = useSelector((state: any) =>
    state.admin.get_seller_list ? state.admin.get_seller_list : {}
  )
  const get_seller_profile: any = useSelector((state: any) =>
    state.admin.get_seller_profile ? state.admin.get_seller_profile : {}
  )
  console.log(get_product_list);
  

  const ITEMS_PER_PAGE = 10

  const hendleToaddPer = (e: any) => {
    console.log(e?.buseniss_id?.slug)

    setroleID(e)
    setaddpermission(true)
    dispatch(adminAction.getsellerprofile({ id: e?.buseniss_id?.slug }))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => { }
  }

  const hendleModalShow = (e: any) => {
    setShowDelete(true)
    setproductID(e)
  }

  // useEffect(() => {
  //   const getData = () => {
  //     // showLoader();

  //     fetch('https://jsonplaceholder.typicode.com/comments')
  //       .then((response) => response.json())
  //       .then((json) => {
  //         // hideLoader();
  //         setComments(json)

  //       })
  //   }

  //   getData()
  // }, [])

  const comments = get_product_list?.data ? get_product_list?.data : []

  const headers = [
    { name: 'S.No.', field: '_id', sortable: false },
    { name: 'User Name', field: 'name', sortable: false },
    { name: 'Role', field: 'role', sortable: false },
    { name: 'Mobile No.', field: 'mobile', sortable: false },
    // {name: 'Vefiry', field: 'verify', sortable: false},
    // {name: 'Deleted', field: 'delete', sortable: false},
    // {name: 'Package', field: 'package', sortable: false},
    { name: 'Action', field: 'action', sortable: false },
  ]

  const commentsData = comments
  // useMemo(() => {
  //   let computedComments = comments

  //   // if (search) {
  //   //     computedComments = computedComments.filter(
  //   //         comment =>
  //   //             comment.name.toLowerCase().includes(search.toLowerCase()) ||
  //   //             comment.role_name.toLowerCase().includes(search.toLowerCase())
  //   //     );
  //   // }

  //   setTotalItems(computedComments.length)

  //   //Sorting comments
  //   if (sorting.field) {
  //     const reversed = sorting.order === 'asc' ? 1 : -1
  //     computedComments = computedComments.sort(
  //       (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  //     )
  //   }

  //   //Current Page slice
  //   return computedComments.slice(
  //     (currentPage - 1) * ITEMS_PER_PAGE,
  //     (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  //   )
  // }, [get_product_list, currentPage,   sorting])

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }



  const handlesearch = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);


    setpageNo(1)
    setSearch(event)

    if (!event) {
      dispatch(adminAction.getSellerList(1))

      return () => { }
    } else {
      dispatch(
        adminAction.getSellerListsearch({ pageNumber: 1, key: event })
      );

      return () => { };
    }
  }
  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    if (!search) {
      dispatch(adminAction.getSellerList(data))

      return () => { }
    } else {
      dispatch(
        adminAction.getSellerListsearch({ pageNumber: data, key: search })
      );

      return () => { };
    }
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
  }, [itemOffset, itemsPerPage, comments])

  // Table shoorting Function

  const onSortingChange = (field: any) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    setSorting({ field, order })
  }

  const hendleChangerole = (e: any) => {
    const { name, value } = e.target
    setroledata({ ...roledata, [name]: value })
  }

  // roledata

  const submitProduct = () => {
    try {
      // const {data: auth} = await login(values.role_name, values.role_description)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData()

      // data.append("title", imageEditfor);

      // images

      // role_name: '',
      // role_description: '',
      // password: '',
      // confrimPassword: '',
      // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      if (!roledata?.role_name) {
        toast.error('please enter seller name')
      } else if (!roledata?.role_description) {
        toast.error('please enter seller email')
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(roledata?.role_description)) {
        toast.error('invalid email')
      } else if (!roledata?.password) {
        toast.error('please enter password')
      } else if (!roledata?.confrimPassword) {
        toast.error('please enter confirm password')
      } else if (roledata?.password !== roledata?.confrimPassword) {
        toast.error('password not match')
      } else {
        setLoading(true)

        data.append('fullName', roledata?.role_name)
        data.append('email', roledata?.role_description)
        data.append('password', roledata?.password)
        data.append('confirmPassword', roledata?.confrimPassword)
        // data.append('sellerId', '20')
        // data.append('featureImage', images[0]?.file)
        // imagesss?.forEach((file: any) => {
        //   data.append('images', file?.file)
        // })

        dispatch(
          adminAction.createSellerByAdmin({
            fullName: roledata?.role_name,
            email: roledata?.role_description,
            password: roledata?.password,
            confirmPassword: roledata?.confrimPassword,
          })
        )
        setShow(false)
        setLoading(false)
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (imagesss?.length == 0) {
        // toast.error('please select Image')
      } else {
        setLoading(true)
        try {
          // const {data: auth} = await login(values.role_name, values.role_description)
          // saveAuth(auth)
          // const {data: user} = await getUserByToken(auth.token)
          // dispatch(adminAction.createRoles(values))
          const data = new FormData()

          // data.append("title", imageEditfor);

          // images

          data.append('title', values?.role_name)
          data.append('tegs', values?.role_description)
          data.append('categoryName', 'test cate')
          data.append('categoryId', '11')
          data.append('sellerId', '20')
          data.append('featureImage', images[0]?.file)
          imagesss?.forEach((file: any) => {
            data.append('images', file?.file)
          })

          dispatch(adminAction.createProduct(data))
          setShow(false)
          setLoading(false)

          // setCurrentUser(user)
        } catch (error) {
          // saveAuth(undefined)
          // setStatus('The login details are incorrect')
          // setSubmitting(false)
          // setLoading(false)
        }
      }
    },
  })

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    dispatch(adminAction.getSellerList(1))
    // dispatch(adminAction.getPackageListbuy({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => { }
  }, [])

  // create product

  // createProduct

  const hendleCreateProduct = () => {
    const data = new FormData()

    // data.append("title", imageEditfor);

    // images

    imagesss?.forEach((file) => { })

    //
  }

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false)
    dispatch(adminAction.deleteseller({ id: productID, pageNumber: pageNo }))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => { }
  }

  const hendleStatusUpdate = (id: any, status: any) => {
    // statusProduct
    dispatch(adminAction.statusSeller({ productId: id, status: status, pageNo: pageNo }))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => { }
  }

  // createPurchaseAdmin

  const [packageShow, setpackageShow] = useState<any>(false)

  const handleClosepackage = () => setpackageShow(false)

  const [subList, setsubList] = useState<any>([])
  const [subListCity, setsubListCity] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedOption2, setSelectedOption2] = useState<any>(null)
  const [sellerID, setsellerID] = useState<any>('')
  const [sellerName, setsellerName] = useState<any>('')

  useEffect(() => {
    const dropList: any = get_package_list_buy?.map((data: any, i: any) => {
      data.value = data?.isoCode
      data.label = data?.name

      return data
    })

    setsubList(dropList)
  }, [get_package_list_buy])

  const hendlePackageBoxShow = (e: any) => {
    setpackageShow(true)
    setsellerID(e?._id)
    setsellerName(e?.fullName)
  }

  const paidStatusList = [
    { value: 'Unpaid', label: 'Unpaid' },
    { value: 'Paid', label: 'Paid' },
  ]

  const submitpackge = () => {
    if (selectedOption == null) {
      toast.error('please select peckage')
    } else if (selectedOption == null) {
      toast.error('please select paid status')
    } else {
      setpackageShow(false)

      // statusProduct
      dispatch(
        adminAction.createPurchaseAdmin({
          userId: sellerID,
          userName: sellerName,
          packageId: selectedOption?._id,
          packageName: selectedOption?.name,
          amount: selectedOption?.amount,
          transationId: '',
          paidstatus: selectedOption2?.label,
        })
      )
      // dispatch(adminAction.getRoleList('dhsg'))
      return () => { }
    }
  }

  // updatesellerByAdmin

  const [sellerEditShow, setsellerEditShow] = useState<any>(false)

  const handleClosesellerEdit = () => setsellerEditShow(false)

  const [editSellData, seteditSellData] = useState<any>({})

  const onChangeDataEdit = (e: any) => {
    const { name, value } = e.target
    seteditSellData({ ...editSellData, [name]: value })
  }

  const countryList = Country.getAllCountries()
  const cityListList = City.getAllCities()

  const [subList2, setsubList2] = useState<any>([])
  const [subListCity2, setsubListCity2] = useState<any>([])
  const [selectedOptioncontry, setSelectedOptioncontry] = useState<any>(null)
  const [selectedOptionCity, setSelectedOptionCity] = useState<any>(null)

  console.log(selectedOptioncontry)

  useEffect(() => {
    const dropList: any = countryList?.map((data: any) => {
      data.value = data?.isoCode
      data.label = data?.name

      return data
    })

    setsubList2(dropList)
  }, [countryList])

  useEffect(() => {
    const dropList = cityListList?.map((data: any, i: any) => {
      data.value = data?.stateCode + i
      data.label = data?.name

      return data
    })

    setsubListCity2(dropList)
  }, [cityListList])

  const [subListCityfil, setsubListCityfil] = useState<any>([])

  useEffect(() => {
    // const found = subListCity.find(
    //   (element) => element?.countryCode == selectedOption?.isoCode
    // );

    const listFilCity =
      cityListList &&
      cityListList?.filter((data: any) => {
        return data?.countryCode == selectedOptioncontry?.isoCode
      })

    setsubListCityfil(listFilCity)
  }, [selectedOptioncontry])

  const hendleeditProductBox = (e: any) => {
    // address
    // city
    // country,

    // const selectContry =
    //   subList2 &&
    //   subList2?.filter((data: any) => {
    //     return data?.name == e?.country
    //   })

    // let selectContry = subList2.find((o: any) => o.name === e?.country)

    // const listFilCity =
    //   cityListList &&
    //   cityListList?.filter((data: any) => {
    //     return data?.countryCode == selectContry?.isoCode
    //   })
    // let obj2 = listFilCity.find((o: any) => o.name === e?.city)
    // setsubListCityfil(listFilCity)
    // setSelectedOptioncontry(selectContry)
    // setSelectedOptionCity(obj2)

    seteditSellData({
      Name: e?.Name,
      // businessName: e?.buseniss_id?.businessName,
      // address: e?.address,
      id: e?.id,
      // city:e?.city,
    })

    setsellerEditShow(true)
  }

  const editSubmit = () => {
    if (!editSellData?.Name) {
      toast.error('please enter user name')
    } else {
      dispatch(
        adminAction.updatesellerByAdmin({
          Name: editSellData?.Name,
          id: editSellData?.id,
          pageNumber: pageNo,
        })
      )
      setsellerEditShow(false)
    }
  }

  return (
    <div>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      {/* <!--begin::Content wrapper-->  */}
      <div className='d-flex flex-column flex-column-fluid'>
        {/* <!--begin::Toolbar-->  */}
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          {/* <!--begin::Toolbar container-->  */}
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <Modal show={packageShow} onHide={handleClosepackage}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {' '}
                <div className='card mx-auto '>
                  <div className='card-body'>
                    <div className='mb-3'>
                      <label className='form-label'>Select Package</label>
                      <Select
                        className='coustomSelect'
                        classNamePrefix='coustomSelect'
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={subList}
                      />
                    </div>
                    <div className='mb-3'>
                      <label className='form-label'>Select Paid Status</label>
                      <Select
                        className='coustomSelect'
                        classNamePrefix='coustomSelect'
                        defaultValue={selectedOption2}
                        onChange={setSelectedOption2}
                        options={paidStatusList}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleClosepackage}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={() => {
                    submitpackge()
                  }}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Users Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a user name'
                    name='role_name'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.role_name && formik.errors.role_name },
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Email</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter email'
                    name='role_description'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Password</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter Password'
                    name='password'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Confirm Password</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter Confirm Password'
                    name='confrimPassword'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>

                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={handleClose}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      submitProduct()
                    }}
                    data-kt-permissions-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* seller Edit  */}

            <Modal show={sellerEditShow} onHide={handleClosesellerEdit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Users</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Users Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a users name'
                    name='Name'
                    onChange={(e) => {
                      onChangeDataEdit(e)
                    }}
                    value={editSellData?.Name}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.role_name && formik.errors.role_name },
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={handleClosesellerEdit}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      editSubmit()
                    }}
                    data-kt-permissions-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* View modal */}
            <Modal show={addpermission} size='lg' onHide={handleCloseaddpermission}>
              <Modal.Header closeButton>
                <Modal.Title>Users Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Woohoo, you're reading this text in a modal! */}

                <div className='mb-10 d-flex'>
                  <div className='col-12'>
                    {/* get_seller_profile */}
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>Users Name</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_seller_profile?.fullName}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>Email</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_seller_profile?.email}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>Role</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_seller_profile?.role_name}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>Password</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_seller_profile?.passwordPlan}</p>
                      </div>
                    </div>

                    {/* <h6>Email</h6>
                      <p>{get_seller_profile?.email}</p>
                      <h6>Role</h6>
                      <p>{get_seller_profile?.role_name}</p> */}
                    <h3>Buseniss</h3>

                    <div className='d-flex mb-5'>
                      <div className='col-4'>
                        {' '}
                        <img
                          src={URL.API_BASE_URL + get_seller_profile?.buseniss_id?.logo}
                          style={{ maxHeight: '200px', width: 'auto' }}
                          alt=''
                        />
                        {/* <p>{get_seller_profile?.buseniss_id?.businessName}</p> */}
                      </div>
                      <div className='col-8'>
                        <div className='d-flex'>
                          <div className='col-3'>
                            <h6>Bussniss Name</h6>
                          </div>
                          <div className='col-9'>
                            {' '}
                            <p>{get_seller_profile?.buseniss_id?.businessName}</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className='col-3'>
                            <h6>City</h6>
                          </div>
                          <div className='col-9'>
                            {' '}
                            <p>{get_seller_profile?.buseniss_id?.city}</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className='col-3'>
                            <h6>Country</h6>
                          </div>
                          <div className='col-9'>
                            {' '}
                            <p>{get_seller_profile?.buseniss_id?.country}</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className='col-3'>
                            <h6>Address</h6>
                          </div>
                          <div className='col-9'>
                            {' '}
                            <p>{get_seller_profile?.buseniss_id?.address}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <h6>Bussniss Name</h6>
                      <p>{get_seller_profile?.buseniss_id?.businessName}</p>
                      <h6>City </h6>
                      <p>{get_seller_profile?.buseniss_id?.city}</p>
                      <h6>Country </h6>
                      <p>{get_seller_profile?.buseniss_id?.country}</p>
                      <h6>Address </h6>
                      <p>{get_seller_profile?.buseniss_id?.address}</p> */}
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* delete modal  */}
            <Modal show={showDelelt} onHide={handleCloseDelete}>
              <Modal.Header closeButton>
                <Modal.Title> Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure
                {/* <div className='mb-10 d-flex'>
                  <div>
                    <h6>Feature Image</h6>
                    <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div>
                    <div>
                      <h6>Product Name</h6>
                      <p>{roleID?.title}</p>
                      <h6>Teg</h6>
                      <p>{roleID?.tegs}</p>
                    </div>
                    <h6>product Image</h6>
                    <div className='row'>
                      {roleID?.images &&
             

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseDelete}>
                  No
                </Button>
                <Button variant='primary' onClick={hendleDelete}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <!--begin::Page title-->  */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              {/* <!--begin::Title-->  */}
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                Users
              </h1>
              {/* <!--end::Title-->  */}
              {/* <!--begin::Breadcrumb-->  */}
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>
                  {/* <a href='../../demo1/dist/index.html' className='text-muted text-hover-primary'>
                    Home
                  </a> */}
                  <Link to={'/'} className='text-muted text-hover-primary'>
                    Home
                  </Link>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>Users</li>
                {/* <!--end::Item-->  */}
              </ul>
              {/* <!--end::Breadcrumb-->  */}
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              {/* <!--begin::Filter menu-->  */}
              <div className='m-0'>
                {/* <!--begin::Menu toggle-->  */}

                {/* <!--end::Menu toggle-->  */}
                {/* <!--begin::Menu 1-->  */}
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                  id='kt_menu_637dc7729cedd'
                >
                  {/* <!--begin::Header-->  */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                  </div>
                  {/* <!--end::Header-->  */}
                  {/* <!--begin::Menu separator-->  */}
                  <div className='separator border-gray-200'></div>
                  {/* <!--end::Menu separator-->  */}
                  {/* <!--begin::Form-->  */}
                  <div className='px-7 py-5'>
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Status:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Input-->  */}
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-dropdown-parent='#kt_menu_637dc7729cedd'
                          data-allow-clear='true'
                        >
                          <option></option>
                          <option value='1'>Approved</option>
                          <option value='2'>Pending</option>
                          <option value='2'>In Process</option>
                          <option value='2'>Rejected</option>
                        </select>
                      </div>
                      {/* <!--end::Input-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Member Type:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Options-->  */}
                      <div className='d-flex'>
                        {/* <!--begin::Options-->  */}
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input className='form-check-input' type='checkbox' value='1' />
                          <span className='form-check-label'>Author</span>
                        </label>
                        {/* <!--end::Options-->  */}
                        {/* <!--begin::Options-->  */}
                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='2'
                          //   checked='checked'
                          />
                          <span className='form-check-label'>Customer</span>
                        </label>
                        {/* <!--end::Options-->  */}
                      </div>
                      {/* <!--end::Options-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Notifications:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Switch-->  */}
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='notifications'
                        // checked='checked'
                        />
                        <label className='form-check-label'>Enabled</label>
                      </div>
                      {/* <!--end::Switch-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Actions-->  */}
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                      >
                        Reset
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        Apply
                      </button>
                    </div>
                    {/* <!--end::Actions-->  */}
                  </div>
                  {/* <!--end::Form-->  */}
                </div>
                {/* <!--end::Menu 1-->  */}
              </div>
              {/* <!--end::Filter menu-->  */}
              {/* <!--begin::Secondary button-->  */}
              {/* <!--end::Secondary button-->  */}
              {/* <!--begin::Primary button-->  */}

              {/* <!--end::Primary button-->  */}
            </div>
            {/* <!--end::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>
        {/* <!--end::Toolbar-->  */}
        {/* <!--begin::Content-->  */}
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          {/* <!--begin::Content container-->  */}
          <div id='kt_app_content_container' className='app-container container-xxl'>
            {/* <!--begin::Card-->  */}
            <div className='card card-flush'>
              {/* <!--begin::Card header-->  */}
              <div className='card-header mt-6'>
                {/* <!--begin::Card title-->  */}
                <div className='card-title'>
                  {/* <!--begin::Search-->  */}
                  <div className='d-flex align-items-center position-relative my-1 me-5'>
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->  */}
                    {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='currentColor'
                        />
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span> */}
                    {/* <!--end::Svg Icon-->  */}
                    <input
                      type='text'
                      data-kt-permissions-table-filter='search'
                      className='form-control form-control-solid w-250px ps-15'
                      placeholder='Search Users'
                      onChange={(e:any)=>{handlesearch(e?.target?.value)}}
                    />
                  </div>
                  {/* <!--end::Search-->  */}
                </div>
                {/* <!--end::Card title-->  */}
                {/* <!--begin::Card toolbar-->  */}
                <div className='card-toolbar'>
                  {/* <!--begin::Button-->  */}
                  {/* <button
                    type='button'
                    className='btn btn-light-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_add_permission'
                    onClick={handleShow}
                  >
                    <span className='svg-icon svg-icon-3'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.3'
                          x='2'
                          y='2'
                          width='20'
                          height='20'
                          rx='5'
                          fill='currentColor'
                        />
                        <rect
                          x='10.8891'
                          y='17.8033'
                          width='12'
                          height='2'
                          rx='1'
                          transform='rotate(-90 10.8891 17.8033)'
                          fill='currentColor'
                        />
                        <rect
                          x='6.01041'
                          y='10.9247'
                          width='12'
                          height='2'
                          rx='1'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    Add Seller
                  </button> */}
                  {/* <!--end::Button-->  */}
                  {/* <Button variant='primary' onClick={handleShow}>
                  Launch demo modal
                </Button> */}
                </div>
                {/* <!--end::Card toolbar-->  */}
              </div>
              {/* <!--end::Card header-->  */}
              {/* <!--begin::Card body-->  */}
              <div className='card-body pt-0'>
                {/* <!--begin::Table-->  */}
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                  id='kt_permissions_table'
                >
                  {/* <!--begin::Table head-->  */}
                  <thead>
                    {/* <!--begin::Table row-->  */}
                    {/* <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Name</th>
                    <th className='min-w-250px'>Assigned to</th>
                    <th className='min-w-125px'>Created Date</th>
                    <th className='text-end min-w-100px'>Actions</th>
                  </tr> */}

                    <tr>
                      {headers.map((data: any, i: any) => (
                        <th
                          key={data?.name}
                          onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                        >
                          {data?.name}

                          {sortingField &&
                            sortingField === data?.field &&
                            (sortingOrder === 'asc' ? (
                              //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                              <i className='fa-solid fa-arrow-down'></i>
                            ) : (
                              <i className='fas fa-arrow-alt-up'></i>
                            ))}
                        </th>
                      ))}
                    </tr>

                    {/* <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) => setSorting({field, order})}
                  /> */}
                    {/* <!--end::Table row-->  */}
                  </thead>
                  {/* <!--end::Table head-->  */}
                  {/* <!--begin::Table body-->  */}
                  <tbody className='fw-semibold text-gray-600'>
                    {commentsData?.map((data: any, i: any) => {
                      const So = 1 + i
                      const pgt1 = pageNo - 1
                      const pgt = pgt1 * 10
                      const soNo = pgt + So

                      const liveUri = 'http://dreamslly.com/63bbf79290327404282cf563rd3/'
                      const localUri = 'http://localhost:3000/63bbf79290327404282cf563rd3/'

                      const rd3 = liveUri + data?.email + 'ltsWRd09eDk27C67ksrtl'

                      return (
                        <tr>
                          {/* <!--begin::Name=-->  */}
                          {/* <td>User Management</td> */}
                          {/* <!--end::Name=-->  */}
                          {/* <!--begin::Assigned to=-->  */}
                          <td>{soNo}</td>
                          {/* <!--end::Assigned to=-->  */}
                          {/* <!--begin::Created Date-->  */}
                          <td>{data?.name}</td>
                          <td>{data?.role}</td>

                          {/* <td>{data?.verify ? 'Yes' : 'No'}</td>
                          <td>{data?.deletedSe == '1' ? 'Yes' : 'No'}</td> */}
                          <td>{data?.country_code + " "}{data?.mobile}</td>

                          {/* <!--end::Created Date-->  */}
                          {/* <!--begin::Action=-->  */}
                          <td>
                            {/* <!--begin::Update-->  */}

                            {/* <td className="text-end"> */}

                            {/* <Dropdown>
                              <Dropdown.Toggle
                                variant='btn btn-light btn-active-light-primary btn-sm'
                                id='dropdown-basic'
                              >
                                Action
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleToaddPer(data)
                                  }}
                                >
                                  View
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleModalShow(data?._id)
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}

                            <button
                              data-id='2209'
                              onClick={() => {
                                // hendleToaddPer(data)
                                navigate("/profile?id=" + data?.id)
                              }}
                              className='btn btn-sm btn-info viewItem mt-3'
                            >
                              <i className='fas fa-eye'></i>
                            </button>
                            {/* <button
                              data-id='2209'
                              onClick={() => {
                                hendleModalShow(data?.id)
                              }}
                              className='btn btn-sm btn-danger ms-3 mt-3'
                            >
                              <i className='fas fa-trash-alt'></i>
                            </button>
                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleeditProductBox(data)
                              }}
                              className='btn btn-sm btn-info ms-3 mt-3'
                            >
                              <i className='fas fa-edit'></i>
                            </button> */}

                            {/* <a
                              href={rd3}
                              target={'_blank'}
                              className='btn btn-sm btn-danger ms-3 mt-3'
                            >
                              <i className='fas fa-sign-in-alt'></i>
                            </a> */}

                            {/* <button
                              data-id='2209'
                              onClick={() => {
                                hendlePackageBoxShow(data)
                              }}
                              className='btn btn-sm btn-info viewItem ms-3 mt-3'
                            >
                        
                              Add Package
                            </button> */}

                            {/* <button
                              data-id='2209'
                              onClick={() => {
                                hendleModalShow(data?._id)
                              }}
                              className='btn btn-sm btn-danger ms-3'
                            >
                              <i className="fas fa-sign-in-alt"></i>
                            </button> */}

                            {/* <!--end::Menu--> */}
                            {/* </td> */}
                            {/* <button
                              className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_update_permission'
                            >
                             
                              <span className='svg-icon svg-icon-3'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.3'
                                    d='M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            
                            </button> */}

                            {/* <!--end::Update-->  */}
                            {/* <!--begin::Delete-->  */}
                            {/* <button
                              className='btn btn-icon btn-active-light-primary w-30px h-30px'
                              data-kt-permissions-table-filter='delete_row'
                            >
                            
                              <span className='svg-icon svg-icon-3'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.5'
                                    d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.5'
                                    d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                           
                            </button> */}
                            {/* <!--end::Delete-->  */}
                          </td>
                          {/* <!--end::Action=-->  */}
                        </tr>
                      )
                    })}
                  </tbody>

                  {get_product_list?.total == '0' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <h3>Record Not found</h3>
                    </div>
                  )}
                  {/* <!--end::Table body-->  */}
                </table>
                {/* <div className='col-md-6'> */}
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='next'
                  className='dataTables_paginate paging_simple_numbers category'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel='previous'
                  forcePage={pageNo-1}
                // renderOnZeroPageCount={null}
                />
                {/* </div> */}
                {/* <!--end::Table-->  */}
              </div>

              {/* <!--end::Card body-->  */}
            </div>
            {/* <!--end::Card-->  */}
            {/* <!--begin::Modals-->  */}
            {/* <!--begin::Modal - Add permissions-->  */}
            <div
              className='modal fade'
              id='kt_modal_add_permission'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/* <!--begin::Modal dialog-->  */}
              <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* <!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/* <!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/* <!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Add a Permission</h2>
                    {/* <!--end::Modal title-->  */}
                    {/* <!--begin::Close-->  */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-permissions-modal-action='close'
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                    </div>
                    {/* <!--end::Close-->  */}
                  </div>
                  {/* <!--end::Modal header-->  */}
                  {/* <!--begin::Modal body-->  */}
                  <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                    {/* <!--begin::Form-->  */}
                    <form id='kt_modal_add_permission_form' className='form' action='#'>
                      {/* <!--begin::Input group-->  */}
                      <div className='fv-row mb-7'>
                        {/* <!--begin::Label-->  */}
                        <label className='fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Permission Name</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='popover'
                            data-bs-trigger='hover'
                            data-bs-html='true'
                            data-bs-content='Permission names is required to be unique.'
                          ></i>
                        </label>
                        {/* <!--end::Label-->  */}
                        {/* <!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter a permission name'
                          name='permission_name'
                        />
                        {/* <!--end::Input-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Input group-->  */}
                      <div className='fv-row mb-7'>
                        {/* <!--begin::Checkbox-->  */}
                        <label className='form-check form-check-custom form-check-solid me-9'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            name='permissions_core'
                            id='kt_permissions_core'
                          />
                          <span className='form-check-label' /* for='kt_permissions_core' */>
                            Set as core permission
                          </span>
                        </label>
                        {/* <!--end::Checkbox-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Disclaimer-->  */}
                      <div className='text-gray-600'>
                        Permission set as a<strong className='me-1'>Core Permission</strong>will be
                        locked and
                        <strong className='me-1'>not editable</strong>in future
                      </div>
                      {/* <!--end::Disclaimer-->  */}
                      {/* <!--begin::Actions-->  */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          className='btn btn-light me-3'
                          data-kt-permissions-modal-action='cancel'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-permissions-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      </div>
                      {/* <!--end::Actions-->  */}
                    </form>
                    {/* <!--end::Form-->  */}
                  </div>
                  {/* <!--end::Modal body-->  */}
                </div>
                {/* <!--end::Modal content-->  */}
              </div>
              {/* <!--end::Modal dialog-->  */}
            </div>
            {/* <!--end::Modal - Add permissions-->  */}
            {/* <!--begin::Modal - Update permissions-->  */}
            <div
              className='modal fade'
              id='kt_modal_update_permission'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/* <!--begin::Modal dialog-->  */}
              <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* <!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/* <!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/* <!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Update Permission</h2>
                    {/* <!--end::Modal title-->  */}
                    {/* <!--begin::Close-->  */}
                    <div
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                      data-kt-permissions-modal-action='close'
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                    </div>
                    {/* <!--end::Close-->  */}
                  </div>
                  {/* <!--end::Modal header-->  */}
                  {/* <!--begin::Modal body-->  */}
                  <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                    {/* <!--begin::Notice-->  */}
                    {/* <!--begin::Notice-->  */}
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                      {/* <!--begin::Icon-->  */}
                      {/* <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->  */}
                      <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x='2'
                            y='2'
                            width='20'
                            height='20'
                            rx='10'
                            fill='currentColor'
                          />
                          <rect
                            x='11'
                            y='14'
                            width='7'
                            height='2'
                            rx='1'
                            transform='rotate(-90 11 14)'
                            fill='currentColor'
                          />
                          <rect
                            x='11'
                            y='17'
                            width='2'
                            height='2'
                            rx='1'
                            transform='rotate(-90 11 17)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                      {/* <!--end::Icon-->  */}
                      {/* <!--begin::Wrapper-->  */}
                      <div className='d-flex flex-stack flex-grow-1'>
                        {/* <!--begin::Content-->  */}
                        <div className='fw-semibold'>
                          <div className='fs-6 text-gray-700'>
                            <strong className='me-1'>Warning!</strong>By editing the permission
                            name, you might break the system permissions functionality. Please
                            ensure you're absolutely certain before proceeding.
                          </div>
                        </div>
                        {/* <!--end::Content-->  */}
                      </div>
                      {/* <!--end::Wrapper-->  */}
                    </div>
                    {/* <!--end::Notice-->  */}
                    {/* <!--end::Notice-->  */}
                    {/* <!--begin::Form-->  */}
                    <form id='kt_modal_update_permission_form' className='form' action='#'>
                      {/* <!--begin::Input group-->  */}
                      <div className='fv-row mb-7'>
                        {/* <!--begin::Label-->  */}
                        <label className='fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Permission Name</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='popover'
                            data-bs-trigger='hover'
                            data-bs-html='true'
                            data-bs-content='Permission names is required to be unique.'
                          ></i>
                        </label>
                        {/* <!--end::Label-->  */}
                        {/* <!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid'
                          placeholder='Enter a permission name'
                          name='permission_name'
                        />
                        {/* <!--end::Input-->  */}
                      </div>
                      {/* <!--end::Input group-->  */}
                      {/* <!--begin::Actions-->  */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          className='btn btn-light me-3'
                          data-kt-permissions-modal-action='cancel'
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-permissions-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      </div>
                      {/* <!--end::Actions-->  */}
                    </form>
                    {/* <!--end::Form-->  */}
                  </div>
                  {/* <!--end::Modal body-->  */}
                </div>
                {/* <!--end::Modal content-->  */}
              </div>
              {/* <!--end::Modal dialog-->  */}
            </div>
            {/* <!--end::Modal - Update permissions-->  */}
            {/* <!--end::Modals-->  */}
          </div>
          {/* <!--end::Content container-->  */}
        </div>
        {/* <!--end::Content-->  */}
      </div>
      {/* <!--end::Content wrapper-->  */}
      {/* <!--begin::Footer-->  */}

      {/* <!--end::Footer-->  */}
      {/* </div> */}
    </div>
  )
}

export default Seller
