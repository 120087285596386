export const URL = {
  // API_BASE_URL: "http://cricchamp.in:3008",
  // API_BASE_URL: "http://192.168.0.2:8080",
  API_BASE_URL: "https://asuneed.in",
// 
  // API_BASE_URL: "https://dreamslly.com:3001",
  
  getSellerList: "/api/all_users",
  getAdminBussProfile: "/api/user_profile",
  getCategoryList: "/api/all_labours",
  getProductList: "/api/all_posts",
  createfaqs: "/api/addAdminNotification",
  faqslistadmin: "/api/getAdminNotifications",
  getCategory: "/api/getCategory",
  createLabourCategory: "/api/createLabourCategory",
  deleteLabourCategory: "/api/deleteLabourCategory",
  editLabourCategory: "/api/editLabourCategory",

  updatesellerByAdmin: "/api/verifyLabourProfile",



  topViewproduct: "/api/dashboard",
  dashboard: "/api/dashboard",




  deleteseller: "/apiPublic/deleteUserAdmin",













  createCategory: "/apiPublic/createPlayer",
  updateperentCate: "/apiPublic/editplayerImage",
  deleteCategory: "/apiPublic/playerDelete",
  getsubCategoryList: "/apiPublic/getTeamList",
  sliderList: "/api/user/sliderList",
  subcreateCategory: "/api/addSliderimage",
  deletesubCategory: "/api/deleteSliderimage",
  updateperentsubCate: "/apiPublic/updateteams",
  getsubsubCategoryList: "/apiPublic/getSeriesInfo",
  subsubcreateCategory: "/apiPublic/createSeriesInfos",
  getTeamListforWinerselect: "/apiPublic/getTeamListforWinerselect",
  updateperentsubsubCate: "/apiPublic/updateserSeriesInfo",
  deletesubsubCategory: "/apiPublic/deletesSeriesInfo",
  
 
  updateFaq: "/apiPublic/updateUmpires",
  deleteFaqs: "/apiPublic/deletesUmpires",
  createPackage: "/apiPublic/createReferees",
  getPackageList: "/apiPublic/getRefereesList",
  deletePackage: "/apiPublic/deletesReferees",
  updatePackage: "/apiPublic/updateReferees",
  createBlogs: "/apiPublic/createSeriesTeam",
  getBlogList: "/apiPublic/getSeriesByTeamList",
  updateBlogs: "/apiPublic/updateseriesteams",
  deleteBlog: "/apiPublic/deletesSeriesteams",

  createProduct: "/apiPublic/creatematchInfos",
  deleteProduct: "/apiPublic/deletesmatchInfod",
  statusProduct: "/apiPublic/statusProduct",
  getmatchInfoDetails: "/apiPublic/getmatchInfoDetails",
  createTickets: "/apiPublic/createVenues",
  getTicketList: "/apiPublic/getVenuesList",
  updateVenues: "/apiPublic/updateVenues",
  deletesVenues: "/apiPublic/deletesVenues",
  updateProduct: "/apiPublic/updatesermatchInfos",

  getTransactionList: "/apiPublic/getSquadbymatchs",
  createSquadbymatchs: "/apiPublic/createSquadbymatchs",
  deletesSquadbymatchs: "/apiPublic/deletesSquadbymatchs",
  updateSquadbymatchs: "/apiPublic/updateSquadbymatchs",














  getPermissionsList: "/api/getPermissionsList",
  getRoleList: "/api/getRoleList",
  createRoles: "/api/createRoles",






 
  statusCategory: "/adminApi/statusCategory",
  statusSubCategory: "/adminApi/statusSubCategory",
  statusSubSubCategory: "/adminApi/statusSubSubCategory",
 
  statusSeller: "/adminApi/statusSeller",

  getsubsubcate: "/adminApi/getsubsubcate",
  getSellerListforpro: "/adminApi/getSellerListforpro",
  getcateAllList: "/adminApi/getcateAllList",



  getsubcateAllList: "/adminApi/getsubcateAllList",

  searchProduct: "/adminApi/searchProduct",
 
  searchPackage: "/adminApi/searchPackage",
 
  searchtransaction: "/adminApi/searchtransaction",
  createSellerByAdmin: "/adminApi/createSellerByAdmin",
 
  getmessageSupportList: "/adminApi/getmessageSupportList",
  createMessage: "/adminApi/createMessage",
  searchTicket: "/adminApi/searchTicket",

  copyProduct: "/adminApi/copyProduct",
  getInvoice: "/adminApi/getInvoice",
  getsellerprofile: "/adminApi/getsellerprofile",
  createAbout: "/adminApi/createAbout",
  getPages: "/adminApi/getPages",
  createPrivacyplicys: "/adminApi/updatePage",
  getWhatsappinquire: "/adminApi/getWhatsappinquire",
  searchWhatsappinquire: "/adminApi/searchWhatsappinquire",
  getSubscription: "/adminApi/getSubscription",
  searchSubscription: "/adminApi/searchSubscription",

  createBussnissPro: "/adminApi/createBussnissPro",
  verifyWhatsapp: "/adminApi/verifyWhatsapp",
  updateWhatsaapNumber: "/adminApi/updateWhatsaapNumber",
  updateBussProfile: "/adminApi/updateBussProfile",
  updateBussLogo: "/adminApi/updateBussLogo",
  getInvoceDetails: "/adminApi/getInvoceDetails",
  createAllsettings: "/adminApi/createAllsettings",
  updateInvoiceLogo: "/adminApi/updateInvoiceLogo",
  updateHomeLogo: "/adminApi/updateHomeLogo",
  updateInvoiceProfile: "/adminApi/updateInvoiceProfile",
  getPackageListbuy: "/adminApi/getPackageListbuy",
  createPurchaseAdmin: "/adminApi/createPurchaseAdmin",
  getUpdateTransactionList: "/adminApi/getUpdateTransactionList",
  getEmailenquiry: "/adminApi/getEmailenquiry",
  searchEmailenquiry: "/adminApi/searchEmailenquiry",
  getpageList: "/adminApi/getpageList",
  deletePage: "/adminApi/deletePage",
  createTags: "/adminApi/createTags",
  gettagsList: "/adminApi/gettagsList",
  deletetags: "/adminApi/deletetags",
  updatetags: "/adminApi/updatetags",
  searchtags: "/adminApi/searchtags",

  createFooterPageType: "/adminApi/createFooterPageType",
  footerPageTypesDelete: "/adminApi/footerPageTypesDelete",
  footerPageTypesUpdate: "/adminApi/footerPageTypesUpdate",
  createFooterPageList: "/adminApi/createFooterPageList",
  footerPageTypes: "/adminApi/footerPageTypes",
  getpageListfooter: "/adminApi/getpageListfooter",
  footerPagepageListet: "/adminApi/footerPagepageListet",
  footerPageListsDelete: "/adminApi/footerPageListsDelete",



  productdateWise: "/adminApi/productdateWise",
  productYearWise: "/adminApi/productYearWise",
 
  topViewproductBycate: "/adminApi/topViewproductBycate",
  topViewproductBySeller: "/adminApi/topViewproductBySeller",
  topViewAllType: "/adminApi/topViewAllType",
  createSlider: "/adminApi/createSlider",
  getsliderList: "/adminApi/getsliderList",
  updateSlider: "/adminApi/updateSlider",
  deleteSlider: "/adminApi/deleteSlider",
  createBanners: "/adminApi/createBanners",
  getBannersList: "/adminApi/getBannersList",
  updateBanners: "/adminApi/updateBanners",
  deleteBanners: "/adminApi/deleteBanners",

  createHomePop: "/adminApi/createHomePop",
  gethomePop: "/adminApi/gethomePop",
  updateStatusHomePop: "/adminApi/updateStatusHomePop",

  updateLoginLogo: "/adminApi/updateLoginLogo",
  updateRegisterLogo: "/adminApi/updateRegisterLogo",
  logoandOtherDetais: "/adminApi/logoandOtherDetais",

  
 

  
  
  
  
  
  
  
  




 
 

};
